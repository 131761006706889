import lottie from "lottie-web";

const lottieAnimations = () => {

  $(() => {

  // Home Page
  const categoryTabs = document.querySelectorAll(".home__category__tab");
  if (categoryTabs.length > 0) {
    categoryTabs.forEach((tab) => {
      // Access the data-json attribute (id in this case)
      if (tab.id) {
        const animation = lottie.loadAnimation({
          container: tab,
          renderer: "svg",
          loop: false,
          autoplay: false, // Start animation only on hover or touch
          path: `${ajax_auth_object.baseurl}/assets/lottie-json/${tab.id}.json`,
        });

        animation.goToAndPlay(0, true);

        const playAnimation = () => {
          if (!tab.classList.contains("play")) {
            animation.goToAndPlay(0, true);
            categoryTabs.forEach((tab) => {
              tab.classList.remove("play");
            });
            tab.classList.add("play");
          }
        };

        tab.addEventListener("mouseover", () => {
          playAnimation();
        });

        tab.addEventListener("touchstart", () => {
          playAnimation();
        });
      }
    });
  }

  // Page Servizi
  const tabServizi = document.querySelectorAll(".tabs__btn__item");
  if (tabServizi.length > 0) {

    tabServizi.forEach((tab) => {
      const btn = tab.querySelector(".tabs__btn__btn");

      if (btn.id) {
        const btnId = btn.id;
        const dataJson = "0" + btnId.replace("btn", "") + "_bianco";
        const animation = lottie.loadAnimation({
          container: btn.querySelector(".tabs__btn__animation"),
          renderer: "svg",
          loop: false,
          autoplay: false,
          path: `${ajax_auth_object.baseurl}/assets/lottie-json/${dataJson}.json`,
        });

        if (tab.classList.contains("active")) {
          animation.goToAndPlay(0, true);
        }

        btn.addEventListener("click", () => {
          animation.goToAndPlay(0, true);
        });
      }
    });
  }
  })
};

export default lottieAnimations;
