/**
 * Export all the theme images
 */
import logoComponentB from "../img/logo-component-b.svg";
import logoComponentY from "../img/logo-component-y.svg";
import logoComponentW from "../img/logo-component-w.svg";
import iconArrowRightW from "../img/icon-arrow-right-w.svg";
import iconArrowDownW from "../img/icon-arrow-down-w.svg";
import iconPlus from '../img/icon-plus.svg';
import iconMinus from '../img/icon-minus.svg';
import iconButtonUp from '../img/icon-button-up.svg';

const themeMedia = () => {
  newImage(logoComponentB);
  newImage(logoComponentY);
  newImage(logoComponentW);
  newImage(iconArrowRightW);
  newImage(iconArrowDownW);
  newImage(iconPlus);
  newImage(iconMinus);
  newImage(iconButtonUp);
};

/**
 * Compile image from src folder
 *
 * @param Image source
 */

const newImage = (source) => {
  const newImage = new Image();
  newImage.src = source;
};

export default themeMedia;
